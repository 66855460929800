/*
----------------------------------------
GENERAL SETTINGS
----------------------------------------
*/

// General Typography
$body-background-color: "white";
$body-font: "narrow";
$body-font-weight: "light";
$base-font-color: "gray-14";
$base-font-color-light: "gray-7";
$base-line-height: "4";
$lead-text-color: "blue-5";

// Links
$link-color: "blue-5";

// Visited links
$link-color-visited: "blue-7";

// Hover links
$link-color-hover: "blue-5";

// Headings
$heading-font: "wide";
$heading-font-weight: "bold";
$heading-line-height: "2";
$heading-weight-small: "semibold";

// Layout
$site-max-width: 120rem;
$site-wrap-padding-sm: "2.5";
$site-wrap-padding-lg: "5";
$flex-column-gap: "3";
$grid-column-gap: "6";

// Footer
$footer-background-color: "gray-14";
$footer-text-color: "white";
$footer-link-color: "white";
$footer-link-decoration-line-hover: none;
$footer-padding-y: "4";
$footer-logo-height: 2.3rem;
$footer-logo-width: 4.4rem;

// Header
$header-padding-y: "2";
$header-logo-height: 33px;
$header-logo-width: 160px;
$header-sticky-padding: 67px;

// Navigation
$navigation-link-color: "gray-14";
$navigation-link-weight: "regular";
$navigation-margin-x: "2";
$navigation-padding-x: "0.5";
$navigation-padding-y: "1px";

// Lists
$list-marker-color: "blue-5";

// Internal nav
$internal-nav-active-color: "blue-3";

/*
----------------------------------------
COLORS
----------------------------------------
*/

// Accent
$highlight-color: "yellow";

// Success
$success-color: "green-5";

// Error
$error-color: "red-5";

// Danger
$danger-color: "red-5";
$danger-color-light: "red-3";
$danger-color-lightest: "red-1";

/*
----------------------------------------
Accessibility
----------------------------------------
*/
$focus-color: "yellow";
$focus-offset: "0";
$focus-style: solid;
$focus-width: "0.5";


/*
----------------------------------------
Buttons
----------------------------------------
*/

// Default button
$button-background-color: "gray-1";
$button-border-color: "gray-2";
$button-border-radius: "xs";
$button-border-width: "1px";
$button-font-size: "base";
$button-label-color: "gray-14";
$button-label-weight: "regular";
$button-padding-x: "2";
$button-padding-y: "0.5";

$button-background-color-hover: "gray-2";
$button-border-color-hover: "gray-2";
$button-label-color-hover: "gray-14";

$button-background-color-active: "gray-3";
$button-border-color-active: "gray-2";
$button-label-color-active: "white";

// Primary action buttons
$button-primary-background-color: "blue-5";
$button-primary-border-color: "blue-5";
$button-primary-label-color: "white";

$button-primary-background-color-hover: "blue-3";
$button-primary-border-color-hover: "blue-3";
$button-primary-label-color-hover: "white";

$button-primary-background-color-active: "blue-3";
$button-primary-border-color-active: "blue-3";
$button-primary-label-color-active: "white";

// Danger buttons
$button-danger-background-color: $danger-color;
$button-danger-border-color: $danger-color;
$button-danger-label-color: "white";

$button-danger-background-color-hover: $danger-color-light;
$button-danger-border-color-hover: $danger-color-light;
$button-danger-label-color-hover: "white";

$button-danger-background-color-active: $danger-color-light;
$button-danger-border-color-active: $danger-color-light;
$button-danger-label-color-active: "white";

// Danger buttons
$button-special-background-color: "green-5";
$button-special-border-color: "green-5";
$button-special-label-color: "white";

$button-special-background-color-hover: "green-3";
$button-special-border-color-hover: "green-3";
$button-special-label-color-hover: "white";

$button-special-background-color-active: "green-3";
$button-special-border-color-active: "green-3";
$button-special-label-color-active: "white";

// Small buttons
$button-small-font-size: "small";
$button-small-padding-x: "1.5";
$button-small-padding-y: "0.25";

// Big buttons
$button-big-font-size: "h5";
$button-big-padding-x: "4";
$button-big-padding-y: "1";

// Disabled buttons
$button-background-color-disabled: "gray-2";
$button-border-color-disabled: "gray-2";
$button-label-color-disabled: "white";

/*
----------------------------------------
Banners
----------------------------------------
*/
$banner-background-color: "blue-5";
$banner-font-size: "base";
$banner-text-color: "white";
$banner-padding-y: "2";
$banner-text-align: center;

/*
----------------------------------------
Hero
----------------------------------------
*/
$hero-kicker-color: "gray-14";
$hero-kicker-font-size: "h6";
$hero-background-color: "white";
$hero-heading-font-size: "h1";
$hero-heading-font-color: $base-font-color;
$hero-lead-text-font-size: "h5";
$hero-lead-text-font-color: $base-font-color-light;
$hero-lead-text-line-height: "3";

/*
----------------------------------------
Skipnav
----------------------------------------
*/
$skipnav-background-color: "white";
$skipnav-font-size: "small";
$skipnav-font-color: $base-font-color;

/*
----------------------------------------
Forms
----------------------------------------
*/
$field-border-width: 1px;
$field-border-color: "gray-2";
$field-padding-y: "1";
$field-padding-x: "2";
$field-label-color: $base-font-color;
$field-label-font-size: "small";
$label-font-weight: "regular";
$legend-font-size: "h4";
$legend-font-color: $base-font-color;
$legend-font-weight: "bold";
$legend-font-family: $heading-font;

/*
----------------------------------------
Sections
----------------------------------------
*/
$section-heading-weight: "bold";

/*
----------------------------------------
Spoke colors
----------------------------------------
*/
$spoke-1-dark: "red-5";
$spoke-1-light: "red-3";
$spoke-2-dark: "green-5";
$spoke-2-light: "green-3";

/*
----------------------------------------
Focus colors
----------------------------------------
*/
$focus-color-health: $spoke-2-dark;
$focus-color-immigration: $spoke-1-dark;
$focus-color-veterans: "blue-5";
$focus-color-voting: "blue-7";
$focus-color-equity: "brown-5";
$focus-color-international: "brown-3";

$focus-area-colors: (
  "health": $spoke-2-dark,
  "immigration": $spoke-1-dark,
  "veterans": "blue-5",
  "voting": "blue-7",
  "equity": "brown-5",
  "international": "brown-3",
) !default;

/*
----------------------------------------
Project pages
----------------------------------------
*/
$project-hero-overlay-color: "blue-7";