// Sections with background colors
.section--bg-focus {
  background-image: image('focus-bg.svg');
  background-position: -300px center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media('>=desktop') {
    background-position: left center;
    background-size: contain;
    
  }
}

.section--connect {
  background-image: image('connect-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.section--bg-health-plus {
  background-image: image('health-plus-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h3.section__heading {
    background-image: image('health-plus-logo.svg');
    height: 34px;
    text-indent: -999em;
    width: 169px;
  }
}