.nav {
  backface-visibility: hidden;
  background-color: color('white');
  bottom: 0;
  display: none;
  overflow-y: scroll;
  padding: units('2') units('6');
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100vw);
  transition: transform duration('1') ease-in-out;
  width: 100vw;
  z-index: z-index('flyout-menu');
  -webkit-overflow-scrolling: touch;
  
  
  @include media('>=tablet-sm') {
    transform: translateX(50vw);
    width: 50vw;
  }
  
  @include media('>=nav-width') {
    background-color: color('transparent');
    color: color('white');
    display: block;
    overflow: visible;
    padding: 0;
    position: static;
    transform: translateX(0);
    width: 50vw;
    
    a {
      color: white;
      text-decoration: none;
    }
  }
  
  &.is-ready {
    display: block;
    // flex-direction: column-reverse;
    // justify-content: flex-start;
  }
  
  &.is-visible {
    transform: translateX(0);
    transition: transform duration('3') ease-in-out;
  }
}

.nav-items {
  @include media('>=nav-width') {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: units('2');
    width: 100%;
  }
}

.nav-heading {
  color: color($navigation-link-color);
  font-weight: 400;

  @include media('>=nav-width') {
    color: color('white');
    font-size: 1.2em;
    font-weight: bold;
  }
}

.nav-links {
  padding: 0;
  margin-top: 0;
  @include media('>=nav-width') {
    list-style: none;
    margin: 0;
    padding: units('2') 0 0;
    position: absolute;
    left: -99999em;
    opacity: 0;
    transition: .2s linear;
  }

  li {
    list-style: none;
    padding: units('0.5') 0;
  }
}

.nav-link,
.nav-link:visited {  
  text-decoration: none;
  display: block;
  @include media('>=nav-width') {
    padding: units($navigation-padding-y) units($navigation-padding-x);
    border-bottom: 3px transparent solid;
    color: color("white");
  }
  
  svg {
    height: 1em;
    margin: 0 0 -0.4rem 0.5rem;
    width: 1em;
  }
}

.nav-link:hover,
.nav-link--current {
  @include media('>=nav-width') {
    border-bottom-color: color("yellow");
    color: color("yellow");
    text-decoration: none;
  }
}
