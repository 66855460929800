// This file contains all the weird selectors that do not fit well
// into systemic organization. Mostly, these are selectors
// used for specific, one-time-use, contexts

.section--offerings .content-grid {

  .content-grid__item:first-child h4 {
    color: color("blue-5");
  }

  .content-grid__item:nth-child(2) h4 {
    color: color("red-5");
  }

  .content-grid__item:nth-child(3) h4 {
    color: color("green-5");
  }
}

.section--work-with-us {
  .content-grid__item h4 {
    color: color("blue-3");
  }

  .content-grid__item b {
    color: color("green-3")
  }
}

.protected-page-body {
  max-width: 500px;
}

// For Django Debug Toolbar
#djDebug {
  #djDebugToolbar {
    #djHideToolBarButton {
      padding-left: 15px;
    }
    .djDebugPanelButton {
      a, .djdt-contentless {
        padding-left: 15px;
      } 

      input[type=checkbox] {
        width: auto;
        margin-right: 27px;
        appearance: auto;
        &:before {
          display: none;
        }
      }
    }
  } 
}
