.header {
  @include media('>=tablet-sm') {
    background-color: color("white");
    height: $header-sticky-padding;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    &:not(.force-hide):hover,
    .nav-items:focus-within {
      .nav-links {
        position: static; 
        opacity: .9;
      }
    }
  }
  @include media('>=nav-width') {
    background-color: color("gray-14");

    &:hover, &:focus-within {
      height: auto;
    }
  }
}

.header__wrapper {
  align-items: center;
  display: flex;
  overflow: visible;
  justify-content: space-between;
  width: 100%;
  @include media('>=nav-width') {
    align-items: flex-start;
  }
}

.header__logo {
  display: block;
  height: $header-logo-height;
  width: $header-logo-width;

  svg {
    color: color("black");
    @include media('>=nav-width') {
      color: color("white");
    }
  }

  // Hack to remove later
  @include media('>=desktop') {
   margin-right: 200px;
  }
}

.header__logo-link,
.header__logo-link:visited {
  color: color($body-background-color);
  display: block;
  height: $header-logo-height;
  position: relative;
  width: $header-logo-width;

  svg {
    display: block;
    height: $header-logo-height;
    position: relative;
    width: $header-logo-width;
    z-index: 2;
  }
}

.header__logo-link:hover {
  color: color($body-background-color);
}

.header__logo-link:hover::after {
  background-color: color($highlight-color);
  bottom: -6px;
  content: "";
  height: units('1');
  position: absolute;
  width: 100%;
  z-index: 1;
}