
// Node packages
@import "../../../../../node_modules/include-media/dist/_include-media.scss";

// Core files
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/vars/_variables.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_asset-paths.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_border-radius.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_colors.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_durations.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_font-family.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_font-weight.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_line-height.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_measure.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_spacing-units.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/functions/_z-indexes.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_background-color.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_circle-image.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_color.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_flexbox.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_fluid-type.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_focus-outline.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_font-sizes.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_grid-gaps.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_heading-colors.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_image-border.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_quick-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_screen-reader.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/core/mixins/_unstyled-list.scss";

// Design tokens
@import "./core/vars/variables";
@import "./tokens.scss";

// Global styles
@import "../../global/base/reset";
@import "../../global/base/font-faces";
@import "../../global/base/base";
@import "../../global/base/forms";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/layout/_layout.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/layout/_post-content.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/layout/_sections.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/layout/_tight-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_banner.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_buttons.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_cards.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_certifications.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_content-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_flippy-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_footer.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_header.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_hero.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_icons.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_internal-nav.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_nav.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_overlay.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_people-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_pullquotes.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_quote-grid.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_search.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_slider.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/components/_tags.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/pages/_connect.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/toolbox/_accessibility.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/toolbox/_focus.scss";
@import "/home/coforma/coforma.io/website/src/stylesheets/global/typography/_typography.scss";

// Theme CSS
@import "base/_base.scss";
@import "components/_accessibility.scss";
@import "components/_content-grid.scss";
@import "components/_header.scss";
@import "components/_nav.scss";
@import "components/_tags.scss";
@import "layout/_sections.scss";
@import "toolkit/_shame.scss";
@import "typography/_typography.scss";
@import "utilities/_color.scss";
@import "utilities/_margins.scss";

.messages {
    color: color("gray-14");
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        
    }
    .error {
        color: color("red-5");
    }
    .success {
        color: color("green-3");
    }
}

.errorlist {
    float: right;
    li {
        list-style: none;
        color: color("red-5");
    }
    
    + p {
        label {
            color: color("red-5");
        }
        input {
            background: fade-out(color("red-5"), 0.75);
            border-color: color("red-5");
        }
    }
}